<template>
  <div>
    <div class="d-flex">
      <span v-html="localize(value.title)"></span>

      <template v-if="localize(value.tooltip)">
        <v-tooltip left max-width="500">
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-on="{ ...tooltip }" class="ml-1">mdi-information</v-icon>
          </template>
          <span v-html="localize(value.tooltip)"></span>
        </v-tooltip>
      </template>
    </div>

    <div
      v-if="!value.subtitleAsTooltip"
      class="caption mt-4"
      v-html="localize(value.subtitle)"
    ></div>

    <v-alert v-if="dataTransferred" type="warning" text>
      {{
        $t('feedbacks.dataTransferred', {
          type: $t(`feedbacks.processTypes.${dataTransferProcessType}`)
        })
      }}
    </v-alert>

    <div v-if="value.useScale" class="scale-wrapper">
      <div
        v-for="val in availableValues"
        :key="`el_${value.id}_val_${val}`"
        class="scale-value-item text-center"
      >
        <p>{{ getAnchor(val) }}</p>
        <v-icon>{{ getIconClass(val) }}</v-icon>
      </div>
    </div>

    <div class="mt-2">
      <v-sheet v-if="isComparison" color="white" class="pa-2 mb-2">
        <template v-for="item in feedbackData.comparisonData">
          <div
            :key="`comment_item_${value.id}_fb_${item.feedbackId}`"
            class="item-comment"
          >
            <strong>
              {{ $t(`feedbacks.typeOptions.${item.feedbackType}`) }}:
            </strong>

            <span
              v-html="getComparisonComment(value.id, item.feedbackType)"
            ></span>
          </div>
        </template>
      </v-sheet>

      <comment-field
        v-model="itemComment"
        v-if="value.allowComment"
        :type="value.commentFieldType"
        :readOnly="readOnly"
        :printView="printView"
        :value-state="value.commentState"
        @change="saveComment"
      ></comment-field>
    </div>
  </div>
</template>

<script>
import elementMixin from '@/mixins/form-element.js';
import { mapActions, mapState } from 'vuex';
import { errorCodes } from '@/enums/errorCodes.js';

import commentField from './commentField/comment-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    transferredData: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  mixins: [elementMixin],

  data: () => ({
    availableValues: [],
    itemComment: '',
    itemValue: -1,
    dataTransferComplete: false,
    dataTransferred: false,
    dataTransferProcessType: -1
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    isComparison() {
      if (this.feedbackData && this.feedbackData.isComparison) {
        return true;
      }

      return false;
    }
  },

  watch: {
    itemComment() {
      this.value.comment = this.itemComment;
    },

    itemValue() {
      this.value.value = this.itemValue;
    },

    value() {
      this.$input('input', this.value);
    },

    transferredData() {
      if (this.transferredData !== null) {
        this.setTransferredValue(this.transferredData);
      }
    }
  },

  mounted() {
    const values = [];
    for (let i = this.value.minValue; i <= this.value.maxValue; i++) {
      values.push(i);
    }

    this.itemComment = this.value.comment;
    this.itemValue = this.value.value;

    this.availableValues = values;
  },

  methods: {
    ...mapActions({
      saveItemComment: 'feedbacks/saveComment'
    }),

    getAnchor(val) {
      if (
        this.value.anchors === null ||
        this.value.anchors === undefined ||
        this.value.anchors.length === 0
      ) {
        return '';
      }

      const anchor = this.value.anchors.filter((x) => x.value === val);
      if (anchor.length === 0) {
        return '';
      }

      return this.localize(anchor[0].translation);
    },

    getComparisonComment(itemId, feedbackType) {
      var item = this.feedbackData.comparisonData.filter(
        (x) => x.feedbackType === feedbackType
      );
      if (item.length === 0) {
        return `<i class="muted">${this.$t('feedbacks.noComment')}</i>`;
      }

      var val = item[0].comments.filter(
        (x) => x.elementId === this.value.id && x.matrixItemId === null
      );

      if (val.length === 0) {
        return `<i class="muted">${this.$t('feedbacks.noComment')}</i>`;
      }

      return val[0].comment;
    },

    async saveComment() {
      const result = await this.saveItemComment({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        itemId: null,
        comment: this.itemComment
      });

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    setTransferredValue(data) {
      if (!this.value.transferFromElement) {
        return;
      }

      if (this.itemComment && !this.itemComment !== '') {
        return;
      }

      if (data.comment === '') {
        return;
      }

      this.dataTransferred = true;
      this.itemComment = data.comment;
      this.dataTransferProcessType = data.processType;
      this.dataTransferComplete = true;

      this.saveComment();
    }
  },

  components: {
    commentField
  }
};
</script>

<style lang="scss" scoped>
.scale-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 2em;
}

.item-comment {
  white-space: pre-line;
}
</style>
